import { useEffect, useRef, useState } from "react"
import "./logo-animation.component.css"

const LogoAnimation = (props) => {
  const { id, IntroDelay, DelayBetweenAnimations } = props
  const timerRef = useRef()
  const [selectedAnimation, setSelectedAnimation] = useState()
  const [upcomingAnimation, setUpcomingAnimation] = useState()
  const currentIndexRef = useRef(0)
  const upcomingIndexRef = useRef(1)
  const animationsRef = useRef()
  const elRef = useRef()
  const upcomingRef = useRef()
  const isPlayingRef = useRef("current")
  const didLoadRef = useRef()

  const updateFirstRef = () => {
    let index
    if (upcomingIndexRef.current + 1 < animationsRef.current.length) {
      index = upcomingIndexRef.current + 1
    } else {
      index = 0
    }
    currentIndexRef.current = index
    setSelectedAnimation(animationsRef.current[index])
  }

  const prepareSecondAnimation = () => {
    let index
    if (currentIndexRef.current + 1 < animationsRef.current.length) {
      index = currentIndexRef.current + 1
    } else {
      index = 0
    }

    upcomingIndexRef.current = index
    setUpcomingAnimation(animationsRef.current[index])
  }

  // Get items on load
  useEffect(() => {
    const loadItems = () => {
      if (didLoadRef.current) {
        console.log("skip store")
        return
      }

      console.log("load dagger store")
      const store = window.sessionStorage.getItem("__dagger-store__")
      if (store) {
        const animations = JSON.parse(store).animations
        animationsRef.current = animations
        if (animationsRef.current.length > 1) {
          setSelectedAnimation(animations[0])
          setUpcomingAnimation(animations[1])
        }
      } else {
        console.log("session storage not available...")
      }
    }

    document.addEventListener("EVENTS.HEADER_LOADED", loadItems)
    loadItems()
    return () => document.removeEventListener("EVENTS.HEADER_LOADED", loadItems)
  }, [])

  const handleVideoComplete = () => {
    // Swap animations
    let toPlayRef
    if (isPlayingRef.current === "current") {
      console.log("on animation " + currentIndexRef.current + " complete...")
      upcomingRef.current.classList.remove("upcoming-animation")
      elRef.current.classList.add("upcoming-animation")
      toPlayRef = upcomingRef.current
      isPlayingRef.current = "upcoming"
      updateFirstRef()
    } else {
      console.log("on animation " + upcomingIndexRef.current + " complete...")
      upcomingRef.current.classList.add("upcoming-animation")
      elRef.current.classList.remove("upcoming-animation")
      toPlayRef = elRef.current
      isPlayingRef.current = "current"
      prepareSecondAnimation()
    }

    // Play after swap delay
    const sanitizedDelay = DelayBetweenAnimations
      ? DelayBetweenAnimations * 1000
      : 3500
    console.log("play after swap delay: ", sanitizedDelay)
    timerRef.current = setTimeout(() => {
      toPlayRef.play()
    }, sanitizedDelay)
  }

  useEffect(() => {
    if (didLoadRef.current) {
      console.log("didLoadRef -- done")
      return
    }

    if (!selectedAnimation) {
      console.log("video not ready")
      return
    }

    const sanitizedDelay = IntroDelay ? IntroDelay * 1000 : 0
    console.log("video ready -- play after: ", sanitizedDelay)
    didLoadRef.current = true

    setTimeout(() => {
      elRef.current.play()
    }, sanitizedDelay)
  }, [IntroDelay, selectedAnimation])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
  }, [])

  return (
    <>
      <div
        className={
          id
            ? `dagger-logo-animation-container dagger-logo-${id}`
            : "dagger-logo-animation-container"
        }
      >
        <div className="logo-animation-container">
          <div className="logo-animation">
            {selectedAnimation && (
              <video
                ref={elRef}
                muted
                playsInline
                src={`${selectedAnimation}#t=0.1`}
                onEnded={handleVideoComplete}
              />
            )}
            {upcomingAnimation && (
              <video
                ref={upcomingRef}
                muted
                playsInline
                src={`${upcomingAnimation}#t=0.1`}
                onEnded={handleVideoComplete}
                className="upcoming-animation"
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default LogoAnimation
